import { checks } from "@cartographerio/permission";
import { Workspace } from "@cartographerio/types";
import { ReactElement } from "react";

import Container from "../../components/Container";
import {
  InvitationTabOption,
  PageBreadcrumbProps,
} from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import PageTab from "../../components/PageTab";
import PageTabs from "../../components/PageTabs";
import PageTabSelect, { pageTabOption } from "../../components/PageTabSelect";
import RequirePermission from "../../components/RequirePermission";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { routes } from "../../routes";

export interface WorkspacePageHeaderProps {
  workspace: Workspace;
  selected: Exclude<PageBreadcrumbProps["workspacePage"], undefined>;
}

export default function WorkspacePageHeader(
  props: WorkspacePageHeaderProps
): ReactElement {
  const { workspace, selected } = props;

  const codesEnabled = useCodesEnabled(workspace);

  const multiTeam = useTeamsEnabled(workspace);

  return (
    <Container sticky={true} width="wide">
      <PageHeader title={`${workspace.name} (Workspace)`}>
        <PageTabs selected={selected}>
          <PageTab
            id="settings"
            label="Settings"
            to={routes.workspace.settings.url([workspace.alias])}
          />
          <RequirePermission check={checks.billing.workspace(workspace.id)}>
            <PageTab
              id="billing"
              label="Billing"
              to={routes.workspace.billing.settings.url([workspace.alias])}
            />
          </RequirePermission>
          <PageTab
            id="projects"
            label="Projects"
            to={routes.workspace.project.list.url([workspace.alias])}
          />
          {multiTeam && (
            <PageTab
              id="teams"
              label="Teams"
              to={routes.workspace.team.list.url([workspace.alias])}
            />
          )}
          <PageTab
            id="members"
            label="Members"
            to={routes.workspace.member.list.url([workspace.alias])}
          />
          <PageTabSelect<InvitationTabOption>
            id="invitation"
            label="Invitations"
            options={[
              pageTabOption({
                id: "single",
                label: "Single Invitations",
                to: routes.workspace.invitation.list.url([workspace.alias]),
              }),
              ...(codesEnabled
                ? [
                    pageTabOption({
                      id: "code",
                      label: "Invitation Codes",
                      to: routes.workspace.invitation.code.list.url([
                        workspace.alias,
                      ]),
                    }),
                  ]
                : []),
            ]}
          />
        </PageTabs>
      </PageHeader>
    </Container>
  );
}

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  ProjectAlias,
  isProjectAlias,
  ProjectId,
  isProjectId,
  WorkspaceAlias,
  isWorkspaceAlias,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";

export interface RcaWorkspaceStatus {
  workspaceId: WorkspaceId;
  workspaceAlias: WorkspaceAlias;
  workspaceName: string;
  projectId: ProjectId;
  projectAlias: ProjectAlias;
  projectName: string;
  hasQualificationRequirement: boolean;
  numWorkspaceOwners: number;
  numProjectSurveyors: number;
  numTrainedProjectSurveyors: number;
}

export const isRcaWorkspaceStatus = (v: any): v is RcaWorkspaceStatus => {
  return (
    typeof v === "object" &&
    v != null &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "workspaceAlias" in v &&
    isWorkspaceAlias(v.workspaceAlias) &&
    "workspaceName" in v &&
    typeof v.workspaceName === "string" &&
    "projectId" in v &&
    isProjectId(v.projectId) &&
    "projectAlias" in v &&
    isProjectAlias(v.projectAlias) &&
    "projectName" in v &&
    typeof v.projectName === "string" &&
    "hasQualificationRequirement" in v &&
    typeof v.hasQualificationRequirement === "boolean" &&
    "numWorkspaceOwners" in v &&
    typeof v.numWorkspaceOwners === "number" &&
    "numProjectSurveyors" in v &&
    typeof v.numProjectSurveyors === "number" &&
    "numTrainedProjectSurveyors" in v &&
    typeof v.numTrainedProjectSurveyors === "number"
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */

import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  isRcaWorkspaceStatus,
  isSearchResults,
  ProjectId,
  RcaWorkspaceStatus,
  SearchResults,
} from "@cartographerio/types";
import * as fetch from "../../../fetch";
import { contentAs, optionalContentAs } from "../../../response";
import { UrlParts } from "../../../url";
import { PartialParams } from "../../params";
import { RcaWorkspaceStatusSearchOptions } from "./common";

const basePath = "/rca/workspace-status/v1";

export function search(
  apiParams: ApiParams,
  options: PartialParams<RcaWorkspaceStatusSearchOptions> = {}
): IO<SearchResults<RcaWorkspaceStatus>> {
  return fetch
    .get({ apiParams, url: searchUrl(options) })
    .flatMap(
      contentAs(
        "SearchResults<RcaWorkspaceStatus>",
        isSearchResults(isRcaWorkspaceStatus)
      )
    );
}

export function searchUrl(
  options: PartialParams<RcaWorkspaceStatusSearchOptions> = {}
): UrlParts {
  return {
    path: basePath,
    query: { ...options },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  id: ProjectId
): IO<RcaWorkspaceStatus> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(contentAs("RcaWorkspaceStatus", isRcaWorkspaceStatus));
}

export function readOption(
  apiParams: ApiParams,
  id: ProjectId
): IO<Option<RcaWorkspaceStatus>> {
  return fetch
    .get({ apiParams, url: { path: `${basePath}/${id}` } })
    .flatMap(optionalContentAs("RcaWorkspaceStatus", isRcaWorkspaceStatus));
}

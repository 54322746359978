// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import { createEnum } from "@cartographerio/util";

import { KnownWorkspaceFeature } from "../types.generated";

const OfflineMaps: KnownWorkspaceFeature = "OfflineMaps";
const EnableArcgisIntegration: KnownWorkspaceFeature =
  "EnableArcgisIntegration";
const EnableInvitationCodes: KnownWorkspaceFeature = "EnableInvitationCodes";
const EnableTeams: KnownWorkspaceFeature = "EnableTeams";
const PreferTeams: KnownWorkspaceFeature = "PreferTeams";
const PromoteMobile: KnownWorkspaceFeature = "PromoteMobile";
const PromoteQualificationRegister: KnownWorkspaceFeature =
  "PromoteQualificationRegister";
const LockAlias: KnownWorkspaceFeature = "LockAlias";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const KnownWorkspaceFeatureEnum = {
  ...createEnum("KnownWorkspaceFeature", [
    [OfflineMaps, "Enable offline maps on mobile (plus feature)"],
    [
      EnableArcgisIntegration,
      "Enable ArcGIS Online integration (plus feature)",
    ],
    [EnableInvitationCodes, "Enable invitation codes (plus feature)"],
    [EnableTeams, "Enable teams (premium feature)"],
    [PreferTeams, "Prefer teams in the UI (premium feature)"],
    [PromoteMobile, "Promote mobile app"],
    [PromoteQualificationRegister, "Promote qualification register"],
    [LockAlias, "Lock workspace alias (prevent editing)"],
  ]),
  OfflineMaps,
  EnableArcgisIntegration,
  EnableInvitationCodes,
  EnableTeams,
  PreferTeams,
  PromoteMobile,
  PromoteQualificationRegister,
  LockAlias,
};

export default KnownWorkspaceFeatureEnum;

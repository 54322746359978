import {
  PermissionCheck,
  QualificationRole,
  Role,
} from "@cartographerio/types";
import { check } from "../check";

/** @deprecated use higher-level role functions instead */
export function hasRoles(
  roles?: Role[] | null,
  qualificationRoles?: QualificationRole[] | null
): PermissionCheck {
  const rolesLabel =
    roles != null && roles.length > 0 ? ` roles: ${roles.join(", ")}` : "";
  const qualificationRolesLabel =
    qualificationRoles != null && qualificationRoles.length > 0
      ? `${rolesLabel.length > 0 ? " and " : " "}qualification roles: ${qualificationRoles.join(", ")}`
      : "";

  return check.named(
    `Has own ${rolesLabel}${qualificationRolesLabel}`,
    check.hasRoles(roles ?? [], qualificationRoles ?? [], check.never)
  );
}

export const globalAdmin = check.globalAdmin;

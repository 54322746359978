import {
  attributeGroup,
  booleanAttr,
  MapId,
  MapSchema,
  marker,
  numberAttr,
  pointLayer,
  rangeBucket,
  stringAttr,
  timestampAttr,
  unsafeMapId,
  unsafeMapLayerId,
} from "@cartographerio/topo-map";
import {
  cartographerSourceWithDefaults,
  mapSchemaWithDefaults,
} from "../../core";
import {
  moduleNumbersAttr,
  negativeIndex,
  particleSizeAttr,
  positiveIndex,
  surveyTypeAttr,
} from "./helpers";

function morph5Map(mapId: MapId, title: string): MapSchema {
  return mapSchemaWithDefaults({
    mapId,
    title,

    layers: [
      pointLayer({
        layerId: unsafeMapLayerId(mapId as string),
        title,
        source: cartographerSourceWithDefaults({
          layerId: unsafeMapLayerId(mapId as string),
          attribution: [],
        }),
        defaultAttribute: "preliminaryConditionScore",
        defaultZOrder: "timestamp",
        attributes: [
          attributeGroup({
            label: "Survey Details",
            attributes: [
              stringAttr({
                attributeId: "riverName",
                label: "River",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "reachName",
                label: "Reach",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "subreachName",
                label: "Subreach",
                buckets: "auto",
              }),
              moduleNumbersAttr,
              stringAttr({
                attributeId: "projectName",
                label: "Project Name",
                buckets: "auto",
              }),
              stringAttr({
                attributeId: "projectCode",
                label: "MoRPh Correlation Code",
                buckets: "auto",
              }),
              surveyTypeAttr,
              stringAttr({
                attributeId: "scenarioName",
                label: "Scenario",
                buckets: "auto",
              }),
              timestampAttr({
                attributeId: "timestamp",
                label: "Date/Time",
                buckets: "auto",
              }),
            ],
          }),
          attributeGroup({
            label: "River Condition",
            attributes: [
              numberAttr({
                attributeId: "preliminaryConditionScore",
                label: "Condition Score (Preliminary)",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(-5, -4, marker.quality(0.0)),
                  rangeBucket(-4, -3, marker.quality(0.1)),
                  rangeBucket(-3, -2, marker.quality(0.2)),
                  rangeBucket(-2, -1, marker.quality(0.3)),
                  rangeBucket(-1, 0, marker.quality(0.4)),
                  rangeBucket(0, 1, marker.quality(0.5)),
                  rangeBucket(1, 2, marker.quality(0.6)),
                  rangeBucket(2, 3, marker.quality(0.7)),
                  rangeBucket(3, 4, marker.quality(0.8)),
                  rangeBucket(4, 5, marker.quality(0.9)),
                ],
              }),
              numberAttr({
                attributeId: "PositiveIndexAverage",
                label: "Average of Positive Indicators",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(0, 1, marker.quality(0.5)),
                  rangeBucket(1, 2, marker.quality(0.6)),
                  rangeBucket(2, 3, marker.quality(0.7)),
                  rangeBucket(3, 4, marker.quality(0.8)),
                  rangeBucket(4, 5, marker.quality(0.9)),
                ],
              }),
              numberAttr({
                attributeId: "NegativeIndexAverage",
                label: "Average of Negative Indicators",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(-5, -4, marker.quality(0.0)),
                  rangeBucket(-4, -3, marker.quality(0.1)),
                  rangeBucket(-3, -2, marker.quality(0.2)),
                  rangeBucket(-2, -1, marker.quality(0.3)),
                  rangeBucket(-1, 0, marker.quality(0.4)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Dimensions",
            attributes: [
              numberAttr({
                attributeId: "shape",
                label: "River Shape",
                decimalPlaces: 3,
                buckets: [
                  rangeBucket(0.0, 0.5, marker.numeric(0 / 6)),
                  rangeBucket(0.5, 1.0, marker.numeric(1 / 6)),
                  rangeBucket(1.0, 1.5, marker.numeric(2 / 6)),
                  rangeBucket(1.5, 2.0, marker.numeric(3 / 6)),
                  rangeBucket(2.0, 5.0, marker.numeric(4 / 6)),
                  rangeBucket(5.0, 10.0, marker.numeric(5 / 6)),
                  rangeBucket(10.0, null, marker.numeric(6 / 6)),
                ],
              }),
              numberAttr({
                attributeId: "averageWidth",
                label: "Average Width",
                decimalPlaces: 3,
                unit: "m",
                buckets: [
                  rangeBucket(0.0, 1.0, marker.numeric(0 / 6)),
                  rangeBucket(1.0, 2.0, marker.numeric(1 / 6)),
                  rangeBucket(2.0, 3.0, marker.numeric(2 / 6)),
                  rangeBucket(3.0, 5.0, marker.numeric(3 / 6)),
                  rangeBucket(5.0, 10.0, marker.numeric(4 / 6)),
                  rangeBucket(10.0, 20.0, marker.numeric(5 / 6)),
                  rangeBucket(20.0, null, marker.numeric(6 / 6)),
                ],
              }),
            ],
          }),
          attributeGroup({
            label: "Group A",
            attributes: [
              booleanAttr({
                attributeId: "A6",
                label: "A6: Bedrock Reaches",
                buckets: "auto",
              }),
              particleSizeAttr(
                "A7",
                "A7: Coarsest Bed Material Size Class",
                true
              ),
              particleSizeAttr(
                "A8",
                "A8: Average Alluvial Bed Material Size Class",
                false
              ),
            ],
          }),
          attributeGroup({
            label: "Group B",
            attributes: [
              positiveIndex("B1", "B1: Bank Top Vegetation Structure"),
              positiveIndex("B2", "B2: Bank Top Tree Feature Richness"),
              positiveIndex("B3", "B3: Bank Top Water-Related Features"),
              negativeIndex("B4", "B4: Bank Top NNIPS Cover"),
              negativeIndex("B5", "B5: Bank Top Managed Ground Cover"),
            ],
          }),
          // attributeGroup({
          //   label: "Group B (Unnormalized)",
          //   attributes: [
          //     numberAttr({ attributeId: "B1U", label: "B1U: Bank Top Vegetation Structure (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "B2U", label: "B2U: Bank Top Tree Feature Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "B3U", label: "B3U: Bank Top Water-Related Features (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "B4U", label: "B4U: Bank Top NNIPS Cover (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "B5U", label: "B5U: Bank Top Managed Ground Cover (Unnormalized)", buckets: "auto" }),
          //   ],
          // }),
          attributeGroup({
            label: "Group C",
            attributes: [
              positiveIndex(
                "C1",
                "C1: Bank Face Riparian Vegetation Structure"
              ),
              positiveIndex("C2", "C2: Bank Face Tree Feature Richness"),
              positiveIndex("C3", "C3: Bank Face Natural Bank Profile Extent"),
              positiveIndex(
                "C4",
                "C4: Bank Face Natural Bank Profile Richness"
              ),
              positiveIndex(
                "C5",
                "C5: Bank Face Natural Bank Material Richness"
              ),
              positiveIndex("C6", "C6: Bank Face Bare Sediment Extent"),
              negativeIndex(
                "C7",
                "C7: Bank Face Artificial Bank Profile Extent"
              ),
              negativeIndex("C8", "C8: Bank Face Reinforcement Extent"),
              negativeIndex(
                "C9",
                "C9: Bank Face Reinforcement Material Severity"
              ),
              negativeIndex("C10", "C10: Bank Face NNIPS Cover"),
            ],
          }),
          // attributeGroup({
          //   label: "Group C (Unnormalized)",
          //   attributes: [
          //     numberAttr({ attributeId: "C1U", label: "C1U: Bank Face Riparian Vegetation Structure (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C2U", label: "C2U: Bank Face Tree Feature Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C3U", label: "C3U: Bank Face Natural Bank Profile Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C4U", label: "C4U: Bank Face Natural Bank Profile Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C5U", label: "C5U: Bank Face Natural Bank Material Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C6U", label: "C6U: Bank Face Bare Sediment Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C7U", label: "C7U: Bank Face Artificial Bank Profile Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C8U", label: "C8U: Bank Face Reinforcement Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C9U", label: "C9U: Bank Face Reinforcement Material Severity (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "C10U", label: "C10U: Bank Face NNIPS Cover (Unnormalized)", buckets: "auto" }),
          //   ]
          // }),
          attributeGroup({
            label: "Group D",
            attributes: [
              positiveIndex(
                "D1",
                "D1: Channel Margin Aquatic Vegetation Extent"
              ),
              positiveIndex(
                "D2",
                "D2: Channel Margin Aquatic Morphotype Richness"
              ),
              positiveIndex("D3", "D3: Channel Margin Physical Feature Extent"),
              positiveIndex(
                "D4",
                "D4: Channel Margin Physical Feature Richness"
              ),
              negativeIndex("D5", "D5: Channel Margin Artificial Features"),
            ],
          }),
          // attributeGroup({
          //   label: "Group D (Unnormalized)",
          //   attributes: [
          //     numberAttr({ attributeId: "D1U", label: "D1U: Channel Margin Aquatic Vegetation Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "D2U", label: "D2U: Channel Margin Aquatic Morphotype Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "D3U", label: "D3U: Channel Margin Physical Feature Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "D4U", label: "D4U: Channel Margin Physical Feature Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "D5U", label: "D5U: Channel Margin Artificial Features (Unnormalized)", buckets: "auto" }),
          //   ]
          // }),
          attributeGroup({
            label: "Group E",
            attributes: [
              positiveIndex("E1", "E1: Channel Aquatic Morphotype Richness"),
              positiveIndex("E2", "E2: Channel Bed Tree Features Richness"),
              positiveIndex(
                "E3",
                "E3: Channel Bed Hydraulic Features Richness"
              ),
              positiveIndex("E4", "E4: Channel Bed Natural Features Extent"),
              positiveIndex("E5", "E5: Channel Bed Natural Features Richness"),
              positiveIndex("E6", "E6: Channel Bed Material Richness"),
              negativeIndex("E7", "E7: Channel Bed Siltation"),
              negativeIndex("E8", "E8: Channel Bed Reinforcement Extent"),
              negativeIndex("E9", "E9: Channel Bed Reinforcement Severity"),
              negativeIndex(
                "E10",
                "E10: Channel Bed Artificial Features Severity"
              ),
              negativeIndex("E11", "E11: Channel Bed NNIPS Extent"),
              negativeIndex("E12", "E12: Channel Bed Filamentous Algae Extent"),
            ],
          }),
          // attributeGroup({
          //   label: "Group E (Unnormalized)",
          //   attributes: [
          //     numberAttr({ attributeId: "E1U", label: "E1U: Channel Aquatic Morphotype Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E2U", label: "E2U: Channel Bed Tree Features Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E3U", label: "E3U: Channel Bed Hydraulic Features Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E4U", label: "E4U: Channel Bed Natural Features Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E5U", label: "E5U: Channel Bed Natural Features Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E6U", label: "E6U: Channel Bed Material Richness (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E7U", label: "E7U: Channel Bed Siltation (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E8U", label: "E8U: Channel Bed Reinforcement Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E9U", label: "E9U: Channel Bed Reinforcement Severity (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E10U", label: "E10U: Channel Bed Artificial Features Severity (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E11U", label: "E11U: Channel Bed NNIPS Extent (Unnormalized)", buckets: "auto" }),
          //     numberAttr({ attributeId: "E12U", label: "E12U: Channel Bed Filamentous Algae Extent (Unnormalized)", buckets: "auto" }),
          //   ]
          // }),
        ],
      }),
    ],
  });
}

export const MrsMorph5 = morph5Map(unsafeMapId("mrsMorph5"), "MoRPh5");

export const MrsMorph5Pro = morph5Map(unsafeMapId("mrsMorph5Pro"), "MoRPh5");

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  MapLayerId,
  isMapLayerId,
  NamedEmail,
  isNamedEmail,
  ProjectId,
  isProjectId,
  Timestamp,
  isTimestamp,
} from "../core/types.generated";
import { Tagged, bridgesIsTaggedHack as isTagged } from "@cartographerio/util";

export type ArcgisIntegrationId = Tagged<"ArcgisIntegrationId">;

export type ArcgisLayerUrl = Tagged<"ArcgisLayerUrl">;

export type ArcgisAccessToken = Tagged<"ArcgisAccessToken">;

export type ArcgisRefreshToken = Tagged<"ArcgisRefreshToken">;

export type ArcgisAuthCode = Tagged<"ArcgisAuthCode">;

export interface ArcgisIntegration {
  name: string;
  projectId: ProjectId;
  layerId: MapLayerId;
  arcgisLayerUrl: ArcgisLayerUrl;
  exportSimplifiedGeometry: boolean;
  enableScheduledExports: boolean;
  notificationEmail?: NamedEmail | null;
  created: Timestamp;
  id: ArcgisIntegrationId;
}

export interface ArcgisIntegrationCreate {
  name: string;
  projectId: ProjectId;
  layerId: MapLayerId;
  arcgisLayerUrl: ArcgisLayerUrl;
  exportSimplifiedGeometry?: boolean | null;
  enableScheduledExports: boolean;
  notificationEmail?: NamedEmail | null;
}

export interface ArcgisIntegrationUpdate {
  name: string;
  enableScheduledExports: boolean;
  notificationEmail?: NamedEmail | null;
}

export interface ArcgisAuthorization {
  integrationId: ArcgisIntegrationId;
  arcgisUsername?: string | null;
  accessToken: ArcgisAccessToken;
  refreshToken?: ArcgisRefreshToken | null;
  accessTokenExpires?: Timestamp | null;
  refreshTokenExpires?: Timestamp | null;
  created: Timestamp;
}

export interface ArcgisExportComplete {
  message: string;
}

export interface ArcgisExportFailed {
  message: string;
}

export interface ArcgisExportSkipped {
  message: string;
}

export type ArcgisExportResult =
  | ({ type: "Complete" } & ArcgisExportComplete)
  | ({ type: "Failed" } & ArcgisExportFailed)
  | ({ type: "Skipped" } & ArcgisExportSkipped);

export interface ArcgisExportEvent {
  timestamp: Timestamp;
  integrationId: ArcgisIntegrationId;
  layerId: MapLayerId;
  arcgisLayerUrl: ArcgisLayerUrl;
  result: ArcgisExportResult;
}

export interface ArcgisAuthorizationStatus {
  lastAuthorized: Timestamp;
  lastAuthorizedAs?: string | null;
  lastAuthorizationExpires?: Timestamp | null;
}

export interface ArcgisExportStatus {
  lastExported: Timestamp;
  lastExportResult: ArcgisExportResult;
}

export interface ArcgisIntegrationStatus {
  integration: ArcgisIntegration;
  authorization?: ArcgisAuthorizationStatus | null;
  export?: ArcgisExportStatus | null;
}

export const isArcgisIntegrationId = (v: any): v is ArcgisIntegrationId => {
  return isTagged(
    (a0: any): a0 is "ArcgisIntegrationId" => a0 === "ArcgisIntegrationId"
  )(v);
};

export const isArcgisLayerUrl = (v: any): v is ArcgisLayerUrl => {
  return isTagged((a0: any): a0 is "ArcgisLayerUrl" => a0 === "ArcgisLayerUrl")(
    v
  );
};

export const isArcgisAccessToken = (v: any): v is ArcgisAccessToken => {
  return isTagged(
    (a0: any): a0 is "ArcgisAccessToken" => a0 === "ArcgisAccessToken"
  )(v);
};

export const isArcgisRefreshToken = (v: any): v is ArcgisRefreshToken => {
  return isTagged(
    (a0: any): a0 is "ArcgisRefreshToken" => a0 === "ArcgisRefreshToken"
  )(v);
};

export const isArcgisAuthCode = (v: any): v is ArcgisAuthCode => {
  return isTagged((a0: any): a0 is "ArcgisAuthCode" => a0 === "ArcgisAuthCode")(
    v
  );
};

export const isArcgisIntegration = (v: any): v is ArcgisIntegration => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "projectId" in v &&
    isProjectId(v.projectId) &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "arcgisLayerUrl" in v &&
    isArcgisLayerUrl(v.arcgisLayerUrl) &&
    "exportSimplifiedGeometry" in v &&
    typeof v.exportSimplifiedGeometry === "boolean" &&
    "enableScheduledExports" in v &&
    typeof v.enableScheduledExports === "boolean" &&
    (!("notificationEmail" in v) ||
      isNamedEmail(v.notificationEmail) ||
      v.notificationEmail === null) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "id" in v &&
    isArcgisIntegrationId(v.id)
  );
};

export const isArcgisIntegrationCreate = (
  v: any
): v is ArcgisIntegrationCreate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "projectId" in v &&
    isProjectId(v.projectId) &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "arcgisLayerUrl" in v &&
    isArcgisLayerUrl(v.arcgisLayerUrl) &&
    (!("exportSimplifiedGeometry" in v) ||
      typeof v.exportSimplifiedGeometry === "boolean" ||
      v.exportSimplifiedGeometry === null) &&
    "enableScheduledExports" in v &&
    typeof v.enableScheduledExports === "boolean" &&
    (!("notificationEmail" in v) ||
      isNamedEmail(v.notificationEmail) ||
      v.notificationEmail === null)
  );
};

export const isArcgisIntegrationUpdate = (
  v: any
): v is ArcgisIntegrationUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    "name" in v &&
    typeof v.name === "string" &&
    "enableScheduledExports" in v &&
    typeof v.enableScheduledExports === "boolean" &&
    (!("notificationEmail" in v) ||
      isNamedEmail(v.notificationEmail) ||
      v.notificationEmail === null)
  );
};

export const isArcgisAuthorization = (v: any): v is ArcgisAuthorization => {
  return (
    typeof v === "object" &&
    v != null &&
    "integrationId" in v &&
    isArcgisIntegrationId(v.integrationId) &&
    (!("arcgisUsername" in v) ||
      typeof v.arcgisUsername === "string" ||
      v.arcgisUsername === null) &&
    "accessToken" in v &&
    isArcgisAccessToken(v.accessToken) &&
    (!("refreshToken" in v) ||
      isArcgisRefreshToken(v.refreshToken) ||
      v.refreshToken === null) &&
    (!("accessTokenExpires" in v) ||
      isTimestamp(v.accessTokenExpires) ||
      v.accessTokenExpires === null) &&
    (!("refreshTokenExpires" in v) ||
      isTimestamp(v.refreshTokenExpires) ||
      v.refreshTokenExpires === null) &&
    "created" in v &&
    isTimestamp(v.created)
  );
};

export const isArcgisExportComplete = (v: any): v is ArcgisExportComplete => {
  return (
    typeof v === "object" &&
    v != null &&
    "message" in v &&
    typeof v.message === "string"
  );
};

export const isArcgisExportFailed = (v: any): v is ArcgisExportFailed => {
  return (
    typeof v === "object" &&
    v != null &&
    "message" in v &&
    typeof v.message === "string"
  );
};

export const isArcgisExportSkipped = (v: any): v is ArcgisExportSkipped => {
  return (
    typeof v === "object" &&
    v != null &&
    "message" in v &&
    typeof v.message === "string"
  );
};

export const isArcgisExportResult = (v: any): v is ArcgisExportResult => {
  return (
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Complete" &&
      isArcgisExportComplete(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Failed" &&
      isArcgisExportFailed(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Skipped" &&
      isArcgisExportSkipped(v))
  );
};

export const isArcgisExportEvent = (v: any): v is ArcgisExportEvent => {
  return (
    typeof v === "object" &&
    v != null &&
    "timestamp" in v &&
    isTimestamp(v.timestamp) &&
    "integrationId" in v &&
    isArcgisIntegrationId(v.integrationId) &&
    "layerId" in v &&
    isMapLayerId(v.layerId) &&
    "arcgisLayerUrl" in v &&
    isArcgisLayerUrl(v.arcgisLayerUrl) &&
    "result" in v &&
    isArcgisExportResult(v.result)
  );
};

export const isArcgisAuthorizationStatus = (
  v: any
): v is ArcgisAuthorizationStatus => {
  return (
    typeof v === "object" &&
    v != null &&
    "lastAuthorized" in v &&
    isTimestamp(v.lastAuthorized) &&
    (!("lastAuthorizedAs" in v) ||
      typeof v.lastAuthorizedAs === "string" ||
      v.lastAuthorizedAs === null) &&
    (!("lastAuthorizationExpires" in v) ||
      isTimestamp(v.lastAuthorizationExpires) ||
      v.lastAuthorizationExpires === null)
  );
};

export const isArcgisExportStatus = (v: any): v is ArcgisExportStatus => {
  return (
    typeof v === "object" &&
    v != null &&
    "lastExported" in v &&
    isTimestamp(v.lastExported) &&
    "lastExportResult" in v &&
    isArcgisExportResult(v.lastExportResult)
  );
};

export const isArcgisIntegrationStatus = (
  v: any
): v is ArcgisIntegrationStatus => {
  return (
    typeof v === "object" &&
    v != null &&
    "integration" in v &&
    isArcgisIntegration(v.integration) &&
    (!("authorization" in v) ||
      isArcgisAuthorizationStatus(v.authorization) ||
      v.authorization === null) &&
    (!("export" in v) || isArcgisExportStatus(v.export) || v.export === null)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */

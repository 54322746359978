/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

import {
  PlanId,
  isPlanId,
  StripeCouponId,
  isStripeCouponId,
  StripeInvoiceId,
  isStripeInvoiceId,
  StripeSubscriptionId,
  isStripeSubscriptionId,
  Timestamp,
  isTimestamp,
  WorkspaceAlias,
  isWorkspaceAlias,
  WorkspaceId,
  isWorkspaceId,
} from "../core/types.generated";
import {
  TaggedNumber,
  bridgesIsTaggedNumberHack as isTaggedNumber,
} from "@cartographerio/util";

export interface WorkspaceUsage {
  numProjects: number;
  numUsers: number;
  features: WorkspaceFeatureSet;
}

export type BillingType = "Free" | "Commercial" | "NonCommercial";

export type PaidBillingType = "Commercial" | "NonCommercial";

export type Gbp = TaggedNumber<"Gbp">;

export type BillingInterval = "Monthly" | "Yearly";

export type BillingCalculationFilter = "All" | "Selectable" | "Suggested";

export type CurrencyAmount = TaggedNumber<"CurrencyAmount">;

export interface InvoiceTotal {
  excludingTax: CurrencyAmount;
  totalTax: CurrencyAmount;
}

export interface Payment {
  invoiceId: StripeInvoiceId;
  subscriptionId?: StripeSubscriptionId | null;
  created: Timestamp;
  total: InvoiceTotal;
}

export interface PerUserPricingScheme {
  unitAmount: CurrencyAmount;
  flatAmount: CurrencyAmount;
  inclusiveUnits: number;
}

export interface PerProjectPricingScheme {
  unitAmount: CurrencyAmount;
  flatAmount: CurrencyAmount;
  inclusiveUnits: number;
}

export type PricingScheme =
  | ({ type: "PerProject" } & PerProjectPricingScheme)
  | ({ type: "PerUser" } & PerUserPricingScheme);

export interface Plan {
  id: PlanId;
  name: string;
  premium: boolean;
  limits: PlanLimits;
  pricingScheme: PricingScheme;
}

export interface PlanOption {
  plan: Plan;
  selectable: boolean;
}

export interface PlanOptions {
  options: PlanOption[];
  showCallUs: boolean;
}

export interface PlanLimits {
  billingType?: PaidBillingType | null;
  maxProjects?: number | null;
  maxUsers?: number | null;
  features: WorkspaceFeatureSet;
}

export interface CouponDurationOnce {}

export interface CouponDurationForever {}

export interface CouponDurationRepeating {
  inMonths: number;
}

export type CouponDuration =
  | ({ type: "Forever" } & CouponDurationForever)
  | ({ type: "Once" } & CouponDurationOnce)
  | ({ type: "Repeating" } & CouponDurationRepeating);

export interface Coupon {
  id: StripeCouponId;
  name: string;
  discountPercent?: number | null;
  discountAmount?: CurrencyAmount | null;
  applicablePlanIds?: PlanId[] | null;
  duration: CouponDuration;
  maxRedemptions?: number | null;
  redeemBy?: Timestamp | null;
  timesRedeemed: number;
  valid: boolean;
}

export interface PricingCalculation {
  plan: Plan;
  billingInterval: BillingInterval;
  usage: WorkspaceUsage;
  selectable: boolean;
  previewTotal: InvoiceTotal;
}

export type StripeSubscriptionStatus =
  | "incomplete"
  | "incomplete_expired"
  | "trialing"
  | "active"
  | "past_due"
  | "canceled"
  | "unpaid"
  | "paused";

export type WorkspaceFeatureSet = "Starter" | "Plus" | "Premium";

export interface WorkspaceBillingSettings {
  billingType: BillingType;
  unsubscribedTrialEnd?: Timestamp | null;
  unsubscribedCouponId?: StripeCouponId | null;
  planOptionsOverride?: PlanId[] | null;
  enableBilling: boolean;
}

export interface Subscription {
  id: StripeSubscriptionId;
  workspaceId?: WorkspaceId | null;
  status: StripeSubscriptionStatus;
  planId: PlanId;
  billingInterval: BillingInterval;
  monthlyRevenueAmount: CurrencyAmount;
  trialEnd?: Timestamp | null;
  nextInvoiceTotal: InvoiceTotal;
  nextInvoiceDate: Timestamp;
  lastStripeUpdate: Timestamp;
  lastInternalUpdate?: Timestamp | null;
  currentCouponId?: StripeCouponId | null;
}

export interface SubscriptionUpdate {
  planId?: PlanId | null;
  billingInterval?: BillingInterval | null;
}

export type WorkspaceBillingSummary =
  | ({ type: "WorkspaceSubscribed" } & WorkspaceSubscribed)
  | ({ type: "WorkspaceUnsubscribed" } & WorkspaceUnsubscribed);

export interface WorkspaceUnsubscribed {
  workspaceId: WorkspaceId;
  workspaceAlias: WorkspaceAlias;
  workspaceName: string;
  settings: WorkspaceBillingSettings;
  usage: WorkspaceUsage;
}

export interface WorkspaceSubscribed {
  workspaceId: WorkspaceId;
  workspaceAlias: WorkspaceAlias;
  workspaceName: string;
  settings: WorkspaceBillingSettings;
  usage: WorkspaceUsage;
  subscription: Subscription;
  plan: Plan;
}

export const isWorkspaceUsage = (v: any): v is WorkspaceUsage => {
  return (
    typeof v === "object" &&
    v != null &&
    "numProjects" in v &&
    typeof v.numProjects === "number" &&
    "numUsers" in v &&
    typeof v.numUsers === "number" &&
    "features" in v &&
    isWorkspaceFeatureSet(v.features)
  );
};

export const isBillingType = (v: any): v is BillingType => {
  return v === "Free" || v === "Commercial" || v === "NonCommercial";
};

export const isPaidBillingType = (v: any): v is PaidBillingType => {
  return v === "Commercial" || v === "NonCommercial";
};

export const isGbp = (v: any): v is Gbp => {
  return isTaggedNumber((a0: any): a0 is "Gbp" => a0 === "Gbp")(v);
};

export const isBillingInterval = (v: any): v is BillingInterval => {
  return v === "Monthly" || v === "Yearly";
};

export const isBillingCalculationFilter = (
  v: any
): v is BillingCalculationFilter => {
  return v === "All" || v === "Selectable" || v === "Suggested";
};

export const isCurrencyAmount = (v: any): v is CurrencyAmount => {
  return isTaggedNumber(
    (a0: any): a0 is "CurrencyAmount" => a0 === "CurrencyAmount"
  )(v);
};

export const isInvoiceTotal = (v: any): v is InvoiceTotal => {
  return (
    typeof v === "object" &&
    v != null &&
    "excludingTax" in v &&
    isCurrencyAmount(v.excludingTax) &&
    "totalTax" in v &&
    isCurrencyAmount(v.totalTax)
  );
};

export const isPayment = (v: any): v is Payment => {
  return (
    typeof v === "object" &&
    v != null &&
    "invoiceId" in v &&
    isStripeInvoiceId(v.invoiceId) &&
    (!("subscriptionId" in v) ||
      isStripeSubscriptionId(v.subscriptionId) ||
      v.subscriptionId === null) &&
    "created" in v &&
    isTimestamp(v.created) &&
    "total" in v &&
    isInvoiceTotal(v.total)
  );
};

export const isPerUserPricingScheme = (v: any): v is PerUserPricingScheme => {
  return (
    typeof v === "object" &&
    v != null &&
    "unitAmount" in v &&
    isCurrencyAmount(v.unitAmount) &&
    "flatAmount" in v &&
    isCurrencyAmount(v.flatAmount) &&
    "inclusiveUnits" in v &&
    typeof v.inclusiveUnits === "number"
  );
};

export const isPerProjectPricingScheme = (
  v: any
): v is PerProjectPricingScheme => {
  return (
    typeof v === "object" &&
    v != null &&
    "unitAmount" in v &&
    isCurrencyAmount(v.unitAmount) &&
    "flatAmount" in v &&
    isCurrencyAmount(v.flatAmount) &&
    "inclusiveUnits" in v &&
    typeof v.inclusiveUnits === "number"
  );
};

export const isPricingScheme = (v: any): v is PricingScheme => {
  return (
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "PerProject" &&
      isPerProjectPricingScheme(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "PerUser" &&
      isPerUserPricingScheme(v))
  );
};

export const isPlan = (v: any): v is Plan => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isPlanId(v.id) &&
    "name" in v &&
    typeof v.name === "string" &&
    "premium" in v &&
    typeof v.premium === "boolean" &&
    "limits" in v &&
    isPlanLimits(v.limits) &&
    "pricingScheme" in v &&
    isPricingScheme(v.pricingScheme)
  );
};

export const isPlanOption = (v: any): v is PlanOption => {
  return (
    typeof v === "object" &&
    v != null &&
    "plan" in v &&
    isPlan(v.plan) &&
    "selectable" in v &&
    typeof v.selectable === "boolean"
  );
};

export const isPlanOptions = (v: any): v is PlanOptions => {
  return (
    typeof v === "object" &&
    v != null &&
    "options" in v &&
    Array.isArray(v.options) &&
    v.options.every((i: any) => isPlanOption(i)) &&
    "showCallUs" in v &&
    typeof v.showCallUs === "boolean"
  );
};

export const isPlanLimits = (v: any): v is PlanLimits => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("billingType" in v) ||
      isPaidBillingType(v.billingType) ||
      v.billingType === null) &&
    (!("maxProjects" in v) ||
      typeof v.maxProjects === "number" ||
      v.maxProjects === null) &&
    (!("maxUsers" in v) ||
      typeof v.maxUsers === "number" ||
      v.maxUsers === null) &&
    "features" in v &&
    isWorkspaceFeatureSet(v.features)
  );
};

export const isCouponDurationOnce = (v: any): v is CouponDurationOnce => {
  return typeof v === "object" && v != null;
};

export const isCouponDurationForever = (v: any): v is CouponDurationForever => {
  return typeof v === "object" && v != null;
};

export const isCouponDurationRepeating = (
  v: any
): v is CouponDurationRepeating => {
  return (
    typeof v === "object" &&
    v != null &&
    "inMonths" in v &&
    typeof v.inMonths === "number"
  );
};

export const isCouponDuration = (v: any): v is CouponDuration => {
  return (
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Forever" &&
      isCouponDurationForever(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Once" &&
      isCouponDurationOnce(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "Repeating" &&
      isCouponDurationRepeating(v))
  );
};

export const isCoupon = (v: any): v is Coupon => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isStripeCouponId(v.id) &&
    "name" in v &&
    typeof v.name === "string" &&
    (!("discountPercent" in v) ||
      typeof v.discountPercent === "number" ||
      v.discountPercent === null) &&
    (!("discountAmount" in v) ||
      isCurrencyAmount(v.discountAmount) ||
      v.discountAmount === null) &&
    (!("applicablePlanIds" in v) ||
      (Array.isArray(v.applicablePlanIds) &&
        v.applicablePlanIds.every((i: any) => isPlanId(i))) ||
      v.applicablePlanIds === null) &&
    "duration" in v &&
    isCouponDuration(v.duration) &&
    (!("maxRedemptions" in v) ||
      typeof v.maxRedemptions === "number" ||
      v.maxRedemptions === null) &&
    (!("redeemBy" in v) || isTimestamp(v.redeemBy) || v.redeemBy === null) &&
    "timesRedeemed" in v &&
    typeof v.timesRedeemed === "number" &&
    "valid" in v &&
    typeof v.valid === "boolean"
  );
};

export const isPricingCalculation = (v: any): v is PricingCalculation => {
  return (
    typeof v === "object" &&
    v != null &&
    "plan" in v &&
    isPlan(v.plan) &&
    "billingInterval" in v &&
    isBillingInterval(v.billingInterval) &&
    "usage" in v &&
    isWorkspaceUsage(v.usage) &&
    "selectable" in v &&
    typeof v.selectable === "boolean" &&
    "previewTotal" in v &&
    isInvoiceTotal(v.previewTotal)
  );
};

export const isStripeSubscriptionStatus = (
  v: any
): v is StripeSubscriptionStatus => {
  return (
    v === "incomplete" ||
    v === "incomplete_expired" ||
    v === "trialing" ||
    v === "active" ||
    v === "past_due" ||
    v === "canceled" ||
    v === "unpaid" ||
    v === "paused"
  );
};

export const isWorkspaceFeatureSet = (v: any): v is WorkspaceFeatureSet => {
  return v === "Starter" || v === "Plus" || v === "Premium";
};

export const isWorkspaceBillingSettings = (
  v: any
): v is WorkspaceBillingSettings => {
  return (
    typeof v === "object" &&
    v != null &&
    "billingType" in v &&
    isBillingType(v.billingType) &&
    (!("unsubscribedTrialEnd" in v) ||
      isTimestamp(v.unsubscribedTrialEnd) ||
      v.unsubscribedTrialEnd === null) &&
    (!("unsubscribedCouponId" in v) ||
      isStripeCouponId(v.unsubscribedCouponId) ||
      v.unsubscribedCouponId === null) &&
    (!("planOptionsOverride" in v) ||
      (Array.isArray(v.planOptionsOverride) &&
        v.planOptionsOverride.every((i: any) => isPlanId(i))) ||
      v.planOptionsOverride === null) &&
    "enableBilling" in v &&
    typeof v.enableBilling === "boolean"
  );
};

export const isSubscription = (v: any): v is Subscription => {
  return (
    typeof v === "object" &&
    v != null &&
    "id" in v &&
    isStripeSubscriptionId(v.id) &&
    (!("workspaceId" in v) ||
      isWorkspaceId(v.workspaceId) ||
      v.workspaceId === null) &&
    "status" in v &&
    isStripeSubscriptionStatus(v.status) &&
    "planId" in v &&
    isPlanId(v.planId) &&
    "billingInterval" in v &&
    isBillingInterval(v.billingInterval) &&
    "monthlyRevenueAmount" in v &&
    isCurrencyAmount(v.monthlyRevenueAmount) &&
    (!("trialEnd" in v) || isTimestamp(v.trialEnd) || v.trialEnd === null) &&
    "nextInvoiceTotal" in v &&
    isInvoiceTotal(v.nextInvoiceTotal) &&
    "nextInvoiceDate" in v &&
    isTimestamp(v.nextInvoiceDate) &&
    "lastStripeUpdate" in v &&
    isTimestamp(v.lastStripeUpdate) &&
    (!("lastInternalUpdate" in v) ||
      isTimestamp(v.lastInternalUpdate) ||
      v.lastInternalUpdate === null) &&
    (!("currentCouponId" in v) ||
      isStripeCouponId(v.currentCouponId) ||
      v.currentCouponId === null)
  );
};

export const isSubscriptionUpdate = (v: any): v is SubscriptionUpdate => {
  return (
    typeof v === "object" &&
    v != null &&
    (!("planId" in v) || isPlanId(v.planId) || v.planId === null) &&
    (!("billingInterval" in v) ||
      isBillingInterval(v.billingInterval) ||
      v.billingInterval === null)
  );
};

export const isWorkspaceBillingSummary = (
  v: any
): v is WorkspaceBillingSummary => {
  return (
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "WorkspaceSubscribed" &&
      isWorkspaceSubscribed(v)) ||
    (typeof v === "object" &&
      v != null &&
      "type" in v &&
      v.type === "WorkspaceUnsubscribed" &&
      isWorkspaceUnsubscribed(v))
  );
};

export const isWorkspaceUnsubscribed = (v: any): v is WorkspaceUnsubscribed => {
  return (
    typeof v === "object" &&
    v != null &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "workspaceAlias" in v &&
    isWorkspaceAlias(v.workspaceAlias) &&
    "workspaceName" in v &&
    typeof v.workspaceName === "string" &&
    "settings" in v &&
    isWorkspaceBillingSettings(v.settings) &&
    "usage" in v &&
    isWorkspaceUsage(v.usage)
  );
};

export const isWorkspaceSubscribed = (v: any): v is WorkspaceSubscribed => {
  return (
    typeof v === "object" &&
    v != null &&
    "workspaceId" in v &&
    isWorkspaceId(v.workspaceId) &&
    "workspaceAlias" in v &&
    isWorkspaceAlias(v.workspaceAlias) &&
    "workspaceName" in v &&
    typeof v.workspaceName === "string" &&
    "settings" in v &&
    isWorkspaceBillingSettings(v.settings) &&
    "usage" in v &&
    isWorkspaceUsage(v.usage) &&
    "subscription" in v &&
    isSubscription(v.subscription) &&
    "plan" in v &&
    isPlan(v.plan)
  );
};

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-use-before-define */
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-enable @typescript-eslint/no-empty-interface */
